<template>
  <main class="container mx-auto mt-5 px-5 gap-5 flex flex-col">
    <h1 class="text-5xl font-bold text-white">Valheim Dashboard</h1>
    <div
      class="
        rounded-md
        text-white
        bg-zinc-700
        p-5
        shadow-md
        flex
        gap-5
        flex-col
      "
    >
      <div class="justify-between flex">
        <h2
          class="
            font-extrabold
            text-transparent text-2xl
            bg-clip-text bg-gradient-to-r
            from-cyan-500
            to-blue-500
          "
        >
          {{ dataJson["server_name"] }}
        </h2>
        <div class="flex bg-green-800 rounded-lg text-center p-2 gap-2">
          <span class="flex h-5 w-5 my-auto">
            <svg
              class="fill-green-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <div class="invisible bg-stone-900"></div>
          <span class="my-auto text-green-400">{{
            dataJson["player_count"]
          }}</span>
        </div>
      </div>
      <iframe
        class="rounded-lg w-auto"
        title="Inline Frame Example"
        width="300"
        height="600"
        src="https://valheim-map.frankberge.fr/"
      >
      </iframe>
    </div>
  </main>
</template>

<script>
export default {
  name: "dashboard-data",
  data() {
    return {
      dataJson: [],
      timer: "",
    };
  },
  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 10000);
  },
  methods: {
    async fetchData() {
      const res = await fetch("https://valheim.frankberge.fr/status.json");
      const data = await res.json();
      this.dataJson = data;
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
};
</script>

<style>
@import "@/assets/scss/main.scss";
</style>
